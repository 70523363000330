import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Alisha = () => {
  return (
    <Layout>
      <Head title="Alisha Chinai" />
      <h3>Alisha Chinai</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Gujarat
      </h4>

      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/azGDZF2vOX8"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>

      <p>
        Alisha Chinai (born 18 March 1965) is an Indian pop singer known for her
        Indi-pop albums as well as playback singing in Hindi cinema. She began
        her singing career with the album Jadoo in 1985, and by the 1990s she
        had become known as the 'Queen of Indipop'. Her best known songs were
        with producers Anu Malik and Biddu during the 1990s. Her best known song
        is Made in India.
      </p>

      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/t5b4PmgpEaA"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <p>
        <OutboundLink href="https://www.instagram.com/alishachinaiofficial/">
          Instagram
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://en.wikipedia.org/wiki/Alisha_Chinai">
          Wikipedia
        </OutboundLink>
      </p>
    </Layout>
  );
};

export default Alisha;
